import React, { useState, useEffect } from "react";

import "../assets/css/index.css";
import Meta from "./Meta";
import HeroSlider from "../components/include/HeroSlider";
import Card from "../components/include/Card";
import Technology from "../components/include/Technology";
import LetsWork from "../components/include/LetsWork";
import BalanceTime from "../components/include/BalanceTime";
import Chooseus from "../components/include/Chooseus";
import CountSection from "../components/include/CountSection";
import Portfolio from "../components/include/Portfolio";

const Home = () => {
  const [pageData, setPageData] = useState(null);
  useEffect(() => {
    fetch(`https://www.fahimshakir.com/core/wp-json/custom/v1/page/2097`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Service not found');
        }
        return response.json();
      })
      .then(data => {
        if (data && data.title) {
          setPageData(data);
        } else {
        }
      })
      .catch(error => {
        console.error('Error fetching service:', error);

      });
  }, []);

  return (
    <div>
      <Meta
        title={pageData?.seo_title || ''}
        description={pageData?.seo_description || ''}
        keywords={pageData?.seo_keywords || ''}
        head_tag={pageData?.head_tag || ''}
      />
      {
        pageData?.hero_slider != null &&
        <HeroSlider slider={pageData?.hero_slider} />
      }
      <h1 className="title-box">Expert Web Design, Development, and SEO Services to Propel Your Success!</h1>
      {
        pageData?.technology_card != null &&
        <Card data={pageData?.technology_card} />
      }
      {
        pageData?.use_technology != null &&
        <Technology data={pageData} />
      }
      {
        pageData?.work_together != null &&
        <LetsWork data={pageData} />
      }
      <BalanceTime></BalanceTime>
      {
        pageData?.portfolio != null &&
        <Portfolio pageData={pageData}></Portfolio>
      }
      {
        pageData?.why_choose_us != null &&
        <Chooseus data={pageData} />
      }
      {
        pageData?.count_section != null &&
        <CountSection data={pageData?.count_section}></CountSection>
      }

    </div>
  );
};

export default Home;
