import React, { useEffect, useState } from 'react';
import Slider from "react-slick"; 
import { FaStar,FaRegStar } from "react-icons/fa";
import screenshot from "../image/mm.png";


const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-next" onClick={onClick}>
      ❱
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-prev" onClick={onClick}>
      ❰
    </div>
  );
};

const TestimonialCarousel = () => {
  const [testimonial, setTestimonial] = useState(null);
  useEffect(() => {
    fetch(`https://www.fahimshakir.com/core/wp-json/core/v1/testimonial`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Service not found');
        }
        return response.json();
      })
      .then(data => {
        if (data) {
          setTestimonial(data);
        } else {
        }
      })
      .catch(error => {
        console.error('Error fetching service:', error);

      });
  }, []);
 
  const settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
 
  return (
    <section className="section-reviews">
      <div>
        <h4 className="text-head">
          5.0 <FaStar className="orang-color" />{" "}
          <FaStar className="orang-color" /> <FaStar className="orang-color" />{" "}
          <FaStar className="orang-color" /> <FaStar className="orang-color" />{" "}
          Google Reviews
        </h4>
      </div>

      <div className="container f-margin contan">
        <Slider {...settings}>
        {
        testimonial != null &&
        Object.entries(testimonial)
          .filter(([key, item]) => item)
          .map(([key, item], index) => ( 
              <div className="g1" key={index}>
                <div className="h-flex">
                  <div className="h-flex">
                    <div className="h">{item.title.slice(0,1)}</div>
                    <div>
                      <h3 className="clf">{item.title}</h3>
                      <p className="year">{item.excerpt}</p>
                    </div>
                  </div>
                  <span>
                    <img src={screenshot} alt="google" loading="lazy" width="23px" height="20px" />
                  </span>
                </div>
                <div className="st5">
                  <StarRating rating={item.ratting} />
                </div>
                <p className="typ">
                <div dangerouslySetInnerHTML={{ __html: item.content }} />
                </p>
              </div>
            ))}
          
        </Slider>
      </div>
    </section>
  );
};

const StarRating = ({ rating }) => {
  const stars = Array.from({ length: 5 }, (_, index) => index + 1);
  return (
    <div className="st5">
      {stars.map((star) => 
        star <= rating ? <FaStar key={star} /> : <FaRegStar key={star} />
      )}
    </div>
  );
};
export default TestimonialCarousel;
