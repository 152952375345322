import React from "react";
import "../Resume/ResumSection.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Education from "./Education";
import Professional from "./Professional";
import Experience from "./Experience";
import resume from '../Resume/Fahim_Resume.doc'; 

const ResumeSection = ({pageData}) => {
  return (
    <>
      <div className="resume-tab">
        <div className="conatainer">
          <div className="section-title">
          <br />
          <br />
          <br />
            <div dangerouslySetInnerHTML={{ __html: pageData?.experience }} />
          </div>

          <div className="resume-tab-compnent">
            <div className="rn-resume-area">
              <Tabs>
                <TabList className="tabmm">
                  <Tab className="liaas">Education</Tab>
                  <Tab className="liaas">Professional Skills</Tab>
                  <Tab className="liaas">Experience</Tab>
                </TabList>
                <TabPanel>
                  <Education pageData={pageData}/>
                </TabPanel>
                <TabPanel>
                  <Professional  pageData={pageData}/>
                </TabPanel>
                <TabPanel>
                  <Experience pageData={pageData}/>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      <div className="cv-btn">
        <button className="mycv-btn">
          <a href={pageData?.cv_file} className="cvlink" download='Fahim_Resume.doc'>
            {pageData?.cv_title}
          </a>
        </button>
      </div>
    </>
  );
};

export default ResumeSection;
