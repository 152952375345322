import React, { useEffect, useState } from 'react';
import '../Ourportfolio/Main.css'
import MainPortfolio from './MainPortfolio'
import Meta from '../Meta';

const Portfolio = () => {

  const [pageData, setPageData] = useState(null);
  useEffect(() => {
    fetch(`https://www.fahimshakir.com/core/wp-json/custom/v1/page/2091`)
        .then(response => {
            if (!response.ok) {
                throw new Error('Service not found');
            }
            return response.json();
        })
        .then(data => {
            if (data && data.title) {
              setPageData(data);
            } else {
            }
        })
        .catch(error => {
            console.error('Error fetching service:', error);
            
        });
    }, []);
    
    console.log(pageData);
  return (
    <div>
      <Meta
        title={pageData?.seo_title || ''}
        description={pageData?.seo_description || ''}
        keywords= {pageData?.seo_keywords || ''}
        head_tag={pageData?.head_tag || ''}
      />
    <div className='main-port'>
        <h1 className='head-port'>{pageData?.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: pageData?.content }} />
    </div>
    <MainPortfolio/>
    </div>
  )
}

export default Portfolio