import React from "react";
const CountSection = ({ data }) => {
    return (
        <div className="count_section_header">
            <div className="container">
                <div className="count_section_items">
                    {
                        data != null &&
                        Object.entries(data)
                            .filter(([key, value]) => value)
                            .map(([key, value], index) => (
                                <div className="count_section_item">
                                    <div className="content">
                                        <strong>{value.title}</strong>
                                        <h3>{value.description}</h3>
                                    </div>
                                </div>
                            )
                        )
                    }
                </div>
            </div>
        </div>
    )
}
export default CountSection;