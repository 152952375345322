import React from "react";
import screan1 from "../image/Screen.svg";
import screan2 from "../image/Screen2.svg";
import screan3 from "../image/Screen3.svg";

const LetsWork = ({ data }) => {
    let work = data?.work_together;
    return (
        <>
            <div className="lets_work_header">
                <div className="container">
                    <div className="lets_work_title">
                        <h2>{data.work_together_title}</h2>
                    </div>
                    <div className="lets_work_lead">
                        <p>{data.work_together_content}</p>
                    </div>
                    <div className="lets_work_items">
                        {
                            work != null &&
                            Object.entries(work)
                                .filter(([key, value]) => value)
                                .map(([key, value], index) => (
                                    <div className="lets_work_item" key={index}>
                                        <div className="item_cloums">
                                            <div className="svg_loaded">
                                                <img
                                                    src={value.featured_image}
                                                    alt={value.title}
                                                    loading="lazy" width='50px' height='50px'
                                                />
                                            </div>
                                        </div>
                                        <div className="content">
                                            <h2>{value.title}</h2>
                                            <p>{value.description}</p>
                                        </div>
                                    </div>
                                ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default LetsWork;