import React from "react";
import "../Footer/Footer.css";
import { FaWhatsapp } from "react-icons/fa";
import { GoMail } from "react-icons/go";
import { FaSkype } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import { FaLocationArrow } from "react-icons/fa";
import TestimonialCarousel from "../include/Testimonial"
import FacebookPageEmbed from "../../Pages/Facebook";
import { NavLink, Link } from "react-router-dom";
import LazyLoad from "react-lazyload";

const Footer = ({ data, menu, contact }) => {

  return (
    <>
      <TestimonialCarousel />

      <footer>
        <div className="footer_inner_main">
          <div className="container">
            <div className="footer_inner_items">
              <div className="footer_inner_item item_one">
                <h5>About Us</h5>
                <div className="widget_container">
                  <p>
                    <div dangerouslySetInnerHTML={{ __html: data?.footer_desc }} /> <Link to="/about-us.php" className="reed">Readmore</Link></p>
                </div>
              </div>
              <div className="footer_inner_item item_two">
                <h5>Contact Us</h5>
                <div className="widget_container">
                  <ul className="icon_list_items">
                    <li className="icon_list_item">
                      <span className="icon_list_icon">
                        <FaLocationArrow className="information" />
                      </span>
                      <span className="icon_list_text"><div dangerouslySetInnerHTML={{ __html: data?.contact_address }} /></span>
                    </li>
                    <li className="icon_list_item">
                      <span className="icon_list_icon">
                        <FaLocationArrow className="information" />
                      </span>
                      <span className="icon_list_text"><div dangerouslySetInnerHTML={{ __html: data?.contact_address2 }} /></span>
                    </li>
                    {
                      contact != null &&
                      Object.entries(contact) // Convert object to an array of [key, value]
                        .filter(([key, value]) => value) // Filter out empty or undefined values
                        .map(([key, value], index) => (

                          (key === 'gmail' || key === 'gmail2' || key === 'skype' || key === 'phone' || key === 'whatsapp') ? (
                            <li key={index} className="icon_list_item">
                              {
                                key == 'phone' ?
                                  <>
                                    <span className="icon_list_icon">
                                      <IoIosCall className="information" />
                                    </span>
                                    <span className="icon_list_text"><a href={`tel:` + value}>{value}</a></span>
                                  </>
                                  :
                                  key == 'whatsapp' ?
                                    <>
                                      <FaWhatsapp />
                                      <span className="icon_list_text"><a href={`https://wa.me/` + value}>{value}</a></span>
                                    </>
                                    :
                                    key == 'gmail' ?
                                      <>
                                        <span className="icon_list_icon">
                                          <GoMail className="information" />
                                        </span>
                                        <span className="icon_list_text"><a href={`mailto:` + value}>{value}</a></span>
                                      </>
                                      :
                                      key == 'gmail2' ?
                                        <>
                                          <span className="icon_list_icon">
                                            <GoMail className="information" />
                                          </span>
                                          <span className="icon_list_text"><a href={`mailto:` + value}>{value}</a></span>
                                        </>
                                        :
                                        key == 'skype' &&
                                        <>
                                          <span className="icon_list_icon">
                                            <FaSkype className="information" />
                                          </span>
                                          <span className="icon_list_text"><a href={`skype:` + value}>{value}</a></span>
                                        </>
                              }
                            </li>
                          )
                            :
                            null
                        ))
                    }
                  </ul>
                </div>
              </div>
              <div className="footer_inner_item item_three">
                <h5>Usefull Links</h5>
                <div className="widget_container">
                  <ul className="icon_list_items">
                    {
                      menu != null &&
                      Object.entries(menu) // Convert object to an array of [key, value]
                        .filter(([key, value]) => value) // Filter out empty or undefined values
                        .map(([key, value], index) => (

                          <li key={index} className="icon_list_item">
                            <span className="icon_list_text">
                              <Link to={value.slug}>{value.title}</Link>
                            </span>
                          </li>
                        ))
                    }
                  </ul>
                </div>
              </div>
              <div className="footer_inner_item item_four map-content">
                <LazyLoad height={300} offset={100} once>
                  <iframe 
                  src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FFahimShakirWebsiteDeveloper%2F%3Fref%3Dembed_page&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" 
                  width="300" height="300" scrolling="no"
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                </LazyLoad>
              </div>
            </div>
          </div>
          <div className="copyright_section">
            <p>Copyright © {new Date().getFullYear()} <a href="/">{data?.copyright_text}</a> All rights reserved.</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
