import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import "./App.css";
import Header from "./components/Header/Header";
import Home from "./Pages/Home";
import About from "./Pages/About/About";
import Resume from "./Pages/Resume/Resume";
import Service from "./Pages/OurService/Service";
import Portfolio from "./Pages/Ourportfolio/Portfolio";
import Blog from "./Pages/Blog/Blog";
import BlogCategory from "./Pages/Blog/BlogCategory";
import BlogDetails from "./Pages/Blog/Details";
import Footer from "./components/Footer/Footer";
import WhatsAppPopup from "./Pages/Whatsapp";
import Contact from "./Pages/Contact/Contact";
import OpenForm from "./Pages/FormOpen";
import Term from "./Pages/Term-and-condition";
import Privacy from "./Pages/Privacy";
import ScrollProgress from "./Pages/Scrolltop";
import TawkToChat from "./components/TawkToChat";
import NotFound from "./Pages/NotFound";
import ServicePage from "./Pages/ServicePage";


function App() {
  const location = useLocation();

  useEffect(() => {
    // Google Analytics
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname + location.search);

    // Google Tag Manager
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        page: location.pathname,
      }
    });
  }, [location]);
  
  
  const [headerData, setHeaderData] = useState(null);
  const [headerMenu, setHeaderMenu] = useState(null);
  const [footerData, setFooterData] = useState(null);
  const [socialData, setSocialData] = useState(null);
  const [contactData, setContactData] = useState(null);
  const [footerMenu, setFooterMenu] = useState(null);
  
  useEffect(() => {
    fetch(`https://www.fahimshakir.com/core/wp-json/core/v1/header`)
      .then(response => {
          if (!response.ok) {
              throw new Error('Service not found');
          }
          return response.json();
      })
      .then(data => {
          if (data) {
            setHeaderData(data);
          } else {
          }
      })
      .catch(error => {
          console.error('Error fetching service:', error);
    });
    fetch(`https://www.fahimshakir.com/core/wp-json/core/v1/menu/header`)
      .then(response => {
          if (!response.ok) {
              throw new Error('Service not found');
          }
          return response.json();
      })
      .then(data => {
          if (data) {
            setHeaderMenu(data);
          } else {
          }
      })
      .catch(error => {
          console.error('Error fetching service:', error);
    });
    fetch(`https://www.fahimshakir.com/core/wp-json/core/v1/footer`)
    .then(response => {
        if (!response.ok) {
            throw new Error('Service not found');
        }
        return response.json();
    })
    .then(data => {
        if (data && data.footer_desc) {
          setFooterData(data);
        } else {
        }
    })
    .catch(error => {
        console.error('Error fetching service:', error);
  });
    fetch(`https://www.fahimshakir.com/core/wp-json/core/v1/social-icons`)
      .then(response => {
          if (!response.ok) {
              throw new Error('Service not found');
          }
          return response.json();
      })
      .then(data => {
          if (data) {
            setSocialData(data);
          } else {
          }
      })
      .catch(error => {
          console.error('Error fetching service:', error);
    });
    fetch(`https://www.fahimshakir.com/core/wp-json/core/v1/contact`)
      .then(response => {
          if (!response.ok) {
              throw new Error('Service not found');
          }
          return response.json();
      })
      .then(data => {
          if (data) {
            setContactData(data);
          } else {
          }
      })
      .catch(error => {
          console.error('Error fetching service:', error);
    });
    fetch(`https://www.fahimshakir.com/core/wp-json/core/v1/menu/footer`)
      .then(response => {
          if (!response.ok) {
              throw new Error('Service not found');
          }
          return response.json();
      })
      .then(data => {
          if (data) {
            setFooterMenu(data);
          } else {
          }
      })
      .catch(error => {
          console.error('Error fetching service:', error);
    });
  
   
  }, []); 
 
  return (
    <div>
      <Header data={headerData} social={socialData} contact={contactData} menu={headerMenu}/>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about-us.php" element={<About />} />
        <Route exact path="/resume.php" element={<Resume />} />
        <Route exact path="/services.php" element={<Service />} />
        <Route exact path="/portfolio.php" element={<Portfolio />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/blog/:slug" element={<BlogDetails />} />
        <Route exact path="/blog/category/:slug" element={<BlogCategory />} />
        <Route exact path="/contact.php" element={<Contact />} />
        <Route exact path="/terms-and-conditions.php" element={<Term />} />
        <Route exact path="/privacy-policy.php" element={<Privacy />} />
        <Route path=":slug" element={<ServicePage />} />
        <Route path="/not-found" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/not-found" />} />
      </Routes>
      <Footer data={footerData} menu={footerMenu} contact={contactData}/>

      <WhatsAppPopup />
      <OpenForm />
      <ScrollProgress />
      <TawkToChat />
    </div>
  );
}

export default App;
