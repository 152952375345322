import React, { useEffect, useState } from "react";
import "../OurService/service.css";
import Meta from "../Meta";
import Services from "../../components/Services";

import SerCard from "./SerCard";
import SeconCard from "./SeconCard";
const Service = () => {
  const [pageData, setPageData] = useState(null);
  useEffect(() => {
    fetch(`https://www.fahimshakir.com/core/wp-json/custom/v1/page/2093`)
        .then(response => {
            if (!response.ok) {
                throw new Error('Service not found');
            }
            return response.json();
        })
        .then(data => {
            if (data && data.title) {
              setPageData(data);
            } else {
            }
        })
        .catch(error => {
            console.error('Error fetching service:', error);
            
        });
    }, []); 
    
  const cardsData = pageData?.service_repeater_content;
  const benifitContent = pageData?.benifit_content;
  return (
    <div className="our-service">
      <Meta
        title={pageData?.seo_title || ''}
        description={pageData?.seo_description || ''}
        keywords= {pageData?.seo_keywords || ''}
        head_tag={pageData?.head_tag || ''}
      />
      <div className="service-image">
        <h1>{pageData?.title}</h1>
      </div>
      <div className="container4512">
      {
        cardsData != null &&
        Object.entries(cardsData)
          .filter(([key, value]) => value)
          .map(([key, value], index) => (
            <SerCard
              key={index}
              imageSrc={value.featured_image}
              HedingText={value.title}
              paragraphText={value.description}
            />
          ))
        }
      </div>
      
      <div className="container-flaex">
        {
        benifitContent != null &&
        Object.entries(benifitContent)
          .filter(([key, value]) => value)
          .map(([key, value], index) => (
          <SeconCard
            key={index}
            Icon={value.icon}
            HedingText={value.title}
            paragraphText={value.description}
          />
        ))}
      </div>
      <Services content={pageData?.content} />
    </div>
  );
};

export default Service;
