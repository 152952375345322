import React from "react";
import { Link } from "react-router-dom";

const Card = ({data}) => {
  
  return (
    <div className="container" id="thumbnail-style">
      {
					data != null &&
					Object.entries(data)
						.filter(([key, value]) => value)
						.map(([key, value], index) => (
        <div className="col-md-4" key={index}>
          <div className="thumbnail">
            <img
              src={value.featured_image}
              alt={value.title}
              loading="lazy"
              width="75px"
              height="100%"
            />
            <div className="caption">
              <h2>{value.title}</h2>
              <p>{value.description}</p>
              <hr />
              <Link to={value.link} className="btn btn-primary btn-sm">Read More</Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default Card;
