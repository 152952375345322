import React, { useState, useEffect } from "react";
import "react-image-lightbox/style.css";
import "./Port.css";
import Allmain from "./Allmain";
import Slider from "react-slick";
 
const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-next" onClick={onClick}>
      ❱
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-prev" onClick={onClick}>
      ❰
    </div>
  );
};
 
const PortCard = ({data}) => {

  const respos = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
    ],
  };

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 600);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 600);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  
  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  return (
    <div className="prot-items">
    {isDesktop ? (
    <div className="haxx">
      {
        data != null &&
        Object.entries(data) // Convert object to an array of [key, value]
          .filter(([key, value]) => value) // Filter out empty or undefined values
          .map(([key, value], index) => ( 
         <Allmain
          key={index}
          imageSrc={value.featured_image}
          paragraphText={value.link}
          link={value.link}
          images={value.featured_image}
        />
      ))}
      
     
    </div>
    ) : (
      <Slider {...respos}>
        {
        data != null &&
        Object.entries(data) // Convert object to an array of [key, value]
          .filter(([key, value]) => value) // Filter out empty or undefined values
          .map(([key, value], index) => ( 
         <Allmain
          key={index}
          imageSrc={value.featured_image}
          paragraphText={value.link}
          Moreinfo={value.link}
          images={value.featured_image}
        />
      ))}
      
      </Slider>
    )}
    </div>
  );
};

export default PortCard;
