import React, { useEffect, useState } from "react";
// import "../Pages/term.css";
import Meta from "./Meta";

const Privacy = () => {
  const [pageData, setPageData] = useState(null);
  useEffect(() => {
    fetch(`https://www.fahimshakir.com/core/wp-json/custom/v1/page/2084`)
        .then(response => {
            if (!response.ok) {
                throw new Error('Service not found');
            }
            return response.json();
        })
        .then(data => {
            if (data && data.title) {
              setPageData(data);
            } else {
            }
        })
        .catch(error => {
            console.error('Error fetching service:', error);
            
        });
    }, []);  
  return (
    <div>
      <Meta
        title={pageData?.seo_title || ''}
        description={pageData?.seo_description || ''}
        keywords= {pageData?.seo_keywords || ''}
        head_tag={pageData?.head_tag || ''}
      />
      <div className="service-image">
        <h1>Privacy Policy</h1>
      </div>

      <div className="container">
        <div className="col-md-12 text-justify">
          <div dangerouslySetInnerHTML={{ __html: pageData?.content }} />
        </div>
      </div>
    </div>
  );
};

export default Privacy;
