import React, { useState, useEffect } from "react";
import LazyLoad from "react-lazyload";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-next" onClick={onClick}>
      ❱
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-prev" onClick={onClick}>
      ❰
    </div>
  );
};

const YoutubeSlider = ({ data }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 600);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 600);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

 

  return (
    <div className="video-container">
      <div className="container p-0">
        {isDesktop ? (
          <div className="video-grid">
            {
              data != null &&
              Object.entries(data)
                .filter(([key, value]) => value)
                .map(([key, value], index) => (
              <div className="video-item" key={index}>
                <LazyLoad height={300} offset={100} once>
                  <iframe src={value.link} allowFullScreen></iframe>
                </LazyLoad>
              </div>
            ))}
          </div>
        ) : (
          <Slider {...settings}>
            {
              data != null &&
              Object.entries(data)
                .filter(([key, value]) => value)
                .map(([key, value], index) => (
                  <div className="video-item" key={index}>
                    <LazyLoad height={300} offset={100} once>
                      <iframe src={value.link} allowFullScreen></iframe>
                    </LazyLoad>
                  </div>
                ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default YoutubeSlider;
