import React from "react";
import Slider from "react-slick";
import diwaker from "../image/diwakr.webp";
import imran from "../image/imran.webp";
import hershita from "../image/harshita.webp";
import YoutubeSlider from "./YoutubeSlider";
import PortCard from "../../Pages/Ourportfolio/PortCard";
import { NavLink, Link } from "react-router-dom";


const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-next" onClick={onClick}>
      ❱
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-prev" onClick={onClick}>
      ❰
    </div>
  );
};

export default function SimpleSlider({ pageData }) {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const happy_customer = pageData?.happy_customer;
  return (
    <>
      <div className="our-portfolio">
        {
          pageData?.portfolio &&

          <>
            <div className="container p-0">
              <h4 className="text-center sec-review-ti">{pageData?.portfolio_title}</h4>
              <p className="des-choose">{pageData?.portfolio_description}</p>
            </div>
            <PortCard data={pageData?.portfolio} />
            <br />
            <Link to="/portfolio.php" className="viewhere">{pageData?.portfolio_button_text}</Link>
          </>
        }

        <div className="testimonialsection">
          {
            happy_customer &&
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div dangerouslySetInnerHTML={{ __html: pageData?.happy_customer_content }} />
                  <hr className="small" />
                </div>
              </div>
              <div className="container margin uniqe">
                <Slider {...settings}>
                  {
                    happy_customer != null &&
                    Object.entries(happy_customer)
                      .filter(([key, value]) => value)
                      .map(([key, value], index) => (
                        <div className="align-center" key={index}>
                          <img src={value.featured_image} alt="hershita" className="img-circle" width='100px' height='100px' />
                          <p className="experience">{value.description}</p>
                        </div>
                      ))}
                </Slider>
              </div>
            </div>
          }
          <YoutubeSlider data={pageData?.video_slider} />
        </div>
      </div>
    </>
  );
}