import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

const Category = ({ data }) => {
    return (
        <>
            {data && data.length > 0 && (
                <div className="sidebar-widget">
                    <div className="widget-title">
                        <h3 className="title">Categories</h3>
                    </div>
                    <ul className='category-list'>
                        {data.map(category => (
                            <li className="category" key={category.id}>
                                    <Link to={`/blog/category/${category.slug}`}><p>{category.name}</p> <span className="post-count">{category.post_count}</span></Link>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    )
}

export default Category;