import React, { useState } from "react";
import { GoMail } from "react-icons/go";
import { FaSkype } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import '../Contact/Contact.css'
import ContactFormHtml from "../../components/include/ContactFormHtml"

const ContactForm = ({contact, address}) => {
 
  return (
    <>
      <div className="contact-bg">
        <div className="cards-container">
          <div className="d-flax">
              <div className="left-side">
                <h2>Looking for more information?</h2>
                <div className="section-title-bottom-line"></div>
                <ContactFormHtml />
              </div>
            <div className="right-side">
              <h2>Our Address</h2>
              <div className="section-title-bottom-line"></div>
              <div className="panel1">
                <ul className="no-bullet">
                  <li>
                    <strong>{address?.owner}</strong>
                  </li>
                  <li>{address?.contact_address}</li>
                  <li>{address?.contact_address2}</li>
                </ul>
                <hr />
                <ul className="no-bullet">
                  <li>
                    <strong>Follow</strong>
                  </li>
                </ul>
                <ul className="small-block-grid-1 chooseus">
                {
                      contact != null &&
                      Object.entries(contact) // Convert object to an array of [key, value]
                        .filter(([key, value]) => value) // Filter out empty or undefined values
                        .map(([key, value], index) => (

                          (key === 'gmail' || key === 'gmail2' || key === 'skype' || key === 'phone' || key === 'whatsapp') ? (
                            <li key={index}>
                              {
                                key == 'phone' ?
                                  <>
                                      <IoIosCall /><a href={`tel:` + value}>{value}</a>
                                  </>
                                  :
                                  key == 'whatsapp' ?
                                    <>
                                      <FaWhatsapp />
                                      <span className="icon_list_text"><a href={`https://wa.me/` + value}>{value}</a></span>
                                    </>
                                    :
                                    key == 'gmail' ?
                                      <>
                                         <GoMail />
                                         <a href={`mailto:` + value}>{value}</a>
                                      </>
                                      :
                                      key == 'gmail2' ?
                                      <>
                                          <GoMail />
                                          <a href={`mailto:` + value}>{value}</a>
                                      </>
                                        :
                                        key == 'skype' &&
                                        <>
                                            <FaSkype />
                                            <a href={`skype:` + value}>{value}</a>
                                        </>
                              }
                            </li>
                          )
                            :
                            null
                        ))
                    }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;

