import React from "react";
import "../Resume/Professional.css";
const Professional = ({pageData}) => {

  const skill_title = pageData?.skill_title;
  const skill = pageData?.skill;

  return (
    <div className="proggressbar-area">
      <div className="content">
        <span className="subtitle-content">Features</span>
        <h4 className="maintitle">{skill_title}</h4>
          {
            skill != null &&
            Object.entries(skill)
              .filter(([key, value]) => value)
              .map(([key, value], index) => (
                <div className="progress-charts" key={index}>
                  <h6 className="heading heading-h6">{value.title}</h6>
                  <div className="progress">
                  <div className="progress-bar wow fadeInLeft animated nhdj"
                      data-wow-duration="0.5s" data-wow-delay=".3s"
                      role="progressbar" 
                      aria-valuenow={value.persent} 
                      aria-valuemin="0" 
                      aria-valuemax={value.persent}
                      style={{ width: `${value.persent}%` }} 
                      >
                      <span className="percent-label">{value.persent}%</span>
                  </div>
                  </div>
                </div>
              ))
          }
      </div>
    </div>
  );
};

export default Professional;
