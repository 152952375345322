import React from 'react'
import ResumeSingle from "./ResumeSingleCard"

const Experience = ({ pageData }) => {
  const trainer_experience_title = pageData?.trainer_experience_title;
  const trainer_experience = pageData?.trainer_experience;
  const job_experience_title = pageData?.job_experience_title;
  const job_experience = pageData?.job_experience;

  return (
    <div className="personal-experience-content">
      <div className="personal-experience-inner">
        <div className="content">
          <h4 className="maintitle">{job_experience_title}</h4>
          <div className="experience-list">
            {
              job_experience != null &&
              Object.entries(job_experience) // Convert object to an array of [key, value]
                .filter(([key, value]) => value) // Filter out empty or undefined values
                .map(([key, value], index) => (
                  <ResumeSingle value={value} index={index} />
                ))
            }
          </div>
        </div>
      </div>

      <div className="personal-experience-inner">
        <div className="content">
          <h4 className="maintitle">{trainer_experience_title}</h4>
          <div className="experience-list">
            {
              trainer_experience != null &&
              Object.entries(trainer_experience) // Convert object to an array of [key, value]
                .filter(([key, value]) => value) // Filter out empty or undefined values
                .map(([key, value], index) => (
                  <ResumeSingle value={value} index={index} />
                ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Experience