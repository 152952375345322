import { useParams, Navigate, Link } from 'react-router-dom'; // Add Navigate for redirection
import { useEffect, useState } from 'react';
import Category from "../Blog/Category"

import Meta from '../Meta';
import '../../assets/css/blogDetails.css'

const Details = () => {
    const { slug } = useParams();
    const [blog, setBlog] = useState(null);
    const [isNotFound, setIsNotFound] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        fetch(`https://www.fahimshakir.com/core/wp-json/custom/v1/blog/slug/${slug}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Service not found');
                }
                return response.json();
            })
            .then(data => {
                if (data && data.title) {
                    setBlog(data);
                    setIsNotFound(false);
                } else {
                    setIsNotFound(true);
                }
            })
            .catch(error => {
                console.error('Error fetching service:', error);
                setIsNotFound(true)
            });



            fetch(`https://www.fahimshakir.com/core/wp-json/custom/v1/categories`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Categories not found');
                }
                return response.json();
            })
            .then(data => {
                if (Array.isArray(data) && data.length > 0) {
                    setData(data);
                } else {
                }
            })
            .catch(error => {
                console.error('Error fetching Categories:', error);
            });
    }, [slug]);

    if (isNotFound) {
        return <Navigate to="/not-found" />;
    }

    if (!blog) {
        return <div>.</div>;
    }
 
 
    return (
        <>
            <Meta
                title={blog?.seo_title || ''}
                description={blog?.seo_description || ''}
                keywords={blog?.seo_keywords || ''}
                head_tag={blog?.head_tag || ''}
            />
            <div className="cms-blog-details">
                <div className="cms-banner">
                    <div className="banner  container">
                        <h1 className="cms-banner-title">{blog.title}</h1>
                        <div className="cms-banner-breadcrumb">
                            <div className="breadcrumb-item">
                                <Link to="/"><span>Home</span></Link>
                            </div>
                            <div className="breadcrumb-item">
                                <Link to="/blog"><span>Blog</span></Link>
                            </div>
                            <div className="breadcrumb-item active">
                                <span>{blog.title}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="blog-details">
                        <div className="container">
                            <div className="blog-details-wrap">
                                <div className="bd-row">
                                    <div className="blog-details-post">
                                        <div className="single-blog-post">
                                            <div className="blog-image">
                                                <a href="#">
                                                    <img className='featured-image' src={blog.featured_image} alt="{blog.title}" loading="lazy" width={100} height={400} />
                                                </a>
                                                {/* <div className="top-meta">
                                                    {blog.date && (
                                                        <span className="date">
                                                            <span>{new Date(blog.date).getDate()}</span>
                                                            {new Date(blog.date).toLocaleString('default', { month: 'short' })}
                                                        </span>
                                                    )}

                                                </div> */}
                                            </div>

                                            <div className="blog-content">
                                                <div className="blog-meta">
                                                    {/* <span className="author">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-user"><path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" /><circle cx="12" cy="7" r="4" /></svg>
                                                        <span className="comment">{blog.author}</span>
                                                    </span> */}
                                                    <span className="tag">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="currentColor"><path d="M9 1V3H15V1H17V3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H7V1H9ZM20 11H4V19H20V11ZM7 5H4V9H20V5H17V7H15V5H9V7H7V5Z"></path></svg>
                                                        <span>{blog.date}</span>
                                                    </span>
                                                </div>
                                                <h2 className="title">{blog.title}</h2>
                                            </div>
                                            <div className="blog-details-content">
                                                <div className="blog-contents" dangerouslySetInnerHTML={{ __html: blog.content }} />
                                                <div className="blog-details-tag-share">
                                                    <div className="blog-details-tag">
                                                        {blog.tags && blog.tags.length > 0 && (
                                                            <div className="sidebar-widget">
                                                                <span className="label">Tags :</span>
                                                                <ul className="sidebar-tag">
                                                                    {blog.tags.map((tag, index) => (
                                                                        <li key={index}>
                                                                            <a href={`#${tag}`}>{tag}</a>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        )}

                                                    </div>
                                                    <div className="blog-details-share">
                                                        {SocialShare(blog.link)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sidebar-widget other-posts">
                                                <div className="widget-title">
                                                    <h3 className="title">Other Posts</h3>
                                                </div>
                                                {blog.other_posts && blog.other_posts.length > 0 && (
                                                    <div className="techwix-post-pagination">
                                                        {blog.other_posts.map((data, index) => (
                                                            <div className="previous-post" key={index}>
                                                                <div className="blog-pagination-post">
                                                                    <div className="post-thumb">
                                                                        <Link to={`/blog/${data.slug}`}>
                                                                            <span className="flaticon-back">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-chevron-left"><path d="m15 18-6-6 6-6"></path></svg>
                                                                            </span>
                                                                            <img src={data.featured_image} alt={data.title} width={80} height={80} />
                                                                            </Link>
                                                                    </div>
                                                                    <div className="post-content">
                                                                        <h4 className="title">
                                                                            <Link to={`/blog/${data.slug}`}>
                                                                                {data.title}
                                                                            </Link>
                                                                            </h4>
                                                                        <span className="date">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-calendar-days"><path d="M8 2v4"></path><path d="M16 2v4"></path><rect width="18" height="18" x="3" y="4" rx="2"></rect><path d="M3 10h18"></path><path d="M8 14h.01"></path><path d="M12 14h.01"></path><path d="M16 14h.01"></path><path d="M8 18h.01"></path><path d="M12 18h.01"></path><path d="M16 18h.01"></path></svg> 
                                                                            {data.date}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blog-sidebar">
                                        <div className="sidebar-widget">
                                            <div className="widget-title">
                                                <h3 className="title">Popular Posts</h3>
                                            </div>
                                            <div className="recent-posts">
                                                {blog.popular_posts && blog.popular_posts.length > 0 && (
                                                    <ul>
                                                        {blog.popular_posts.map((data, index) => (
                                                            <li key={index}>
                                                                <Link to={`/blog/${data.slug}`} className="post-link">
                                                                    <div className="post-thumb">
                                                                        <img src={data.featured_image} alt={data.title} width={80} height={80} />
                                                                    </div>
                                                                    <div className="post-text">
                                                                        <h4 className="title">{data.title}</h4>
                                                                        <div className="post-meta">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-calendar-days"><path d="M8 2v4"></path><path d="M16 2v4"></path><rect width="18" height="18" x="3" y="4" rx="2"></rect><path d="M3 10h18"></path><path d="M8 14h.01"></path><path d="M12 14h.01"></path><path d="M16 14h.01"></path><path d="M8 18h.01"></path><path d="M12 18h.01"></path><path d="M16 18h.01"></path></svg>
                                                                            <p>{data.date}</p>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                        </div>
                                        <br />
                                        <Category data={data}/>
 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}

const SocialShare = (link) => {
    return (
        <ul className="social-share">
            <li>
                <a
                    className="share-facebook"
                    href={`https://www.facebook.com/sharer/sharer.php?u=${link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="#000" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-facebook">
                        <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
                    </svg>
                </a>
            </li>
            <li>
                <a
                    className="share-twitter"
                    href={`https://twitter.com/intent/tweet?url=${link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <svg width="24" height="24" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path></svg>
                </a>
            </li>
            <li>
                <a
                    className="share-pinterest"
                    href={`https://pinterest.com/pin/create/button/?url=${link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#000" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-instagram">
                        <rect width="20" height="20" x="2" y="2" rx="5" ry="5" />
                        <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
                        <line x1="17.5" x2="17.51" y1="6.5" y2="6.5" />
                    </svg>
                </a>
            </li>
        </ul>
    );
}


export default Details