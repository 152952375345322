import React, { useState, useEffect } from "react";
import banner1 from "../image/a1.webp";
import banner2 from "../image/a2.webp";
import banner3 from "../image/a3.webp";
import banner4 from "../image/first.jpg";
import banner5 from "../image/4.webp";
import banner6 from "../image/2.webp";
import Slider from "react-slick";


const NextArrow = (props) => {
	const { onClick } = props;
	return (
		<div className="rightArrowStyles" onClick={onClick}>
			❱
		</div>
	);
};

const PrevArrow = (props) => {
	const { onClick } = props;
	return (
		<div className="leftArrowStyles" onClick={onClick}>
			❰
		</div>
	);
};


const slideStyles = {
	width: "100%",
	height: "100%",
	backgroundSize: "cover",
	backgroundPosition: "center",
	transition: "all 1s",
};

const dotsContainerStyles = {
	display: "flex",
	justifyContent: "center",
};

const dotStyle = {
	margin: "0 3px",
	cursor: "pointer",
	fontSize: "20px",
}

const HeroSlider = ({ slider }) => {
	const Herosettings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
	};
	const slides = [
		{ url: banner1 },
		{ url: banner4 },
		{ url: banner3 },
		{ url: banner2 },
		{ url: banner5 },
		{ url: banner6 },
	];

	return (
		slider != null &&
		<div className="res hero-slider">
			<Slider {...Herosettings}>
				{
					slider != null &&
					Object.entries(slider)
						.filter(([key, value]) => value)
						.map(([key, value], index) => (
							<img src={value?.banner} alt={`banner-${index + 1}`}
								loading="lazy" key={index} />

						))}
			</Slider>
		</div>

	);
};
export default HeroSlider;
