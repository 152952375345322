import React, { useState, useEffect } from "react";
import "../Header/Header.css";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaPinterest } from "react-icons/fa";
import { ImInstagram } from "react-icons/im";
import { FaLinkedinIn } from "react-icons/fa";
import { VscGithub } from "react-icons/vsc";
import { BsAndroid2 } from "react-icons/bs";
import { GoMail } from "react-icons/go";
import { FaSkype } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import logo from "../image/fs-logo.png";
import { NavLink, Link } from "react-router-dom";
import { FaBars } from "react-icons/fa6";
import { FaXmark } from "react-icons/fa6";

const Header = ({ data, social, contact, menu }) => {
  const [isSticky, setIsSticky] = useState(false);
  const [isActive, setActive] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.pageYOffset;
      if (offset > 180) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = () => {
    setActive(true);
  };

  const closenave = () => {
    setActive(false);
  };

  const navbarClass = isSticky ? "nav-main sticky" : "nav-main";
 
  return (
    <>
      <header>
        <div className="inner">
          <div className="container p-0">
            <div className="inner-section d-flex justify-content-space align-items-center">
              <ul className="social_icons_wrapper">
                {
                  social != null &&
                  Object.entries(social) // Convert object to an array of [key, value]
                    .filter(([key, value]) => value) // Filter out empty or undefined values
                    .map(([key, value], index) => ( // Map each entry to a list item
                      <li key={index} className="grid_item">
                        <a href={value} title={key} target="_blank">
                          {
                            key == 'facebook' ?
                              <FaFacebookF className="icon-color" />
                              : key == 'twitter' ?
                                <FaXTwitter className="icon-color" />
                                : key == 'pinterest' ?
                                  <FaPinterest className="icon-color" />
                                  : key == 'instagram' ?
                                    <ImInstagram className="icon-color" />
                                    : key == 'linkedin' ?
                                      <FaLinkedinIn className="icon-color" />
                                      : key == 'github' ?
                                        <VscGithub className="icon-color" />
                                        :
                                        null
                          }
                        </a>
                      </li>
                    ))
                }
              </ul>
              <div className="icon_list_items_header">
                <ul className="icon_list_items">
                  {
                    contact != null &&
                    Object.entries(contact) // Convert object to an array of [key, value]
                      .filter(([key, value]) => value) // Filter out empty or undefined values
                      .map(([key, value], index) => (

                        (key === 'gmail' || key === 'skype' || key === 'phone' || key === 'whatsapp') ? (
                          <li key={index} className="icon_list_item">
                            {
                              key == 'gmail' ?
                                <a href={`mailto:` + value}>
                                  <GoMail className="information" />
                                  <span className="icon_list_text">{value}</span>
                                </a>
                                :
                                key == 'skype' ?
                                  <a href={`skype:` + value}>
                                    <FaSkype className="information" />
                                    <span className="icon_list_text">{value}</span>
                                  </a>
                                  :
                                  key == 'phone' ?
                                    <a href={`tel:` + value}>
                                      <IoIosCall className="information" />
                                      <span className="icon_list_text">{value}</span>
                                    </a>
                                    :
                                    key == 'whatsapp' &&
                                    <a href={`https://wa.me/` + value}>
                                      <FaWhatsapp className="information" />
                                      <span className="icon_list_text">{value}</span>
                                    </a>
                            }
                          </li>
                        )
                          :
                          null
                      ))
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="inner_two">
          <div className={`container ${navbarClass}`}>
            <div className="d-flex justify-content-space align-items-center">
              <div className="image">
                <Link to="/">
                  {
                    data?.logo_1 &&
                    <img loading="lazy" src={data?.logo_1} alt="Logo" className="image" width='150px' height='90px' />
                  }
                </Link>
              </div>
              <div className="menue">
                <div className="raven-nav-menu-toggle">
                  <div className="raven-nav-menu-toggle-button" onClick={handleClick}>
                    <span className="fa fa-bars"></span>
                  </div>
                </div>
                <ul className={isActive ? "ul raven_nav_menu m-0 active" : "ul raven_nav_menu m-0"}>
                  <span className="close-btn" onClick={closenave}><i className="fa fa-times" aria-hidden="true"></i></span>
                  {
                      menu != null &&
                      Object.entries(menu) // Convert object to an array of [key, value]
                        .filter(([key, value]) => value) // Filter out empty or undefined values
                        .map(([key, value], index) => (

                          <li key={index}>
                            <NavLink to={value.slug} end className={({ isActive }) => (isActive ? 'active' : '')} onClick={() => (setActive(false))}>{value.title}</NavLink>
                          </li>
                        ))
                    }
                </ul>
              </div >
              {
                data?.logo_1 &&
                <div className="button_wrapper">
                  <Link className="button_link" onClick={() => (setActive(false))} to="/contact.php">Contact Us</Link>
                </div>
              }
            </div >
          </div >
        </div > */}
        <div className="inner_two">
          <div className={`container ${navbarClass}`}>
            <div className="d-flex justify-content-space align-items-center">
              <div className="image">
                <Link to="/">
                  <img loading="lazy" src={logo} alt="Logo" className="image" width='150px' height='90px'/>
                </Link>
              </div>
              <div className="menue">
                <div className="raven-nav-menu-toggle">
                  <div className="raven-nav-menu-toggle-button" onClick={handleClick}>
                    <span className="fa fa-bars"></span>
                  </div>
                </div>

                <ul className={isActive ? "ul raven_nav_menu m-0 active" : "ul raven_nav_menu m-0"}>
                  <span className="close-btn" onClick={closenave}><i className="fa fa-times" aria-hidden="true"></i></span>
                  <li>
                    <NavLink to="/" end className={({ isActive }) => (isActive ? 'active' : '')}  onClick={()=>(setActive(false))}>Home</NavLink>
                  </li>
                  <li>
                    <NavLink to="/about-us.php" className={({ isActive }) => (isActive ? 'active' : '')}  onClick={()=>(setActive(false))}>About Us</NavLink>
                  </li>
                  <li>
                    <NavLink to="/resume.php" className={({ isActive }) => (isActive ? 'active' : '')}  onClick={()=>(setActive(false))}>Resume</NavLink>
                  </li>
                  <li>
                    <NavLink to="/services.php" className={({ isActive }) => (isActive ? 'active' : '')}  onClick={()=>(setActive(false))}>Our Services</NavLink>
                  </li>
                  <li>
                    <NavLink to="/portfolio.php" className={({ isActive }) => (isActive ? 'active' : '')} onClick={()=>(setActive(false))}>Our Portfolio</NavLink>
                  </li>
                  <li>
                    <NavLink to="/blog" className={({ isActive }) => (isActive ? 'active' : '')} onClick={()=>(setActive(false))}>Blog</NavLink>
                  </li>
                  <li>
                    <NavLink onClick={()=>(setActive(false))} to="/contact.php" className="button_link">Contact Us</NavLink>
                  </li>
                  </ul >
              </div >
              <div className="button_wrapper">
                <Link className="button_link" onClick={()=>(setActive(false))} to="/contact.php">Contact Us</Link>
              </div>
            </div >
          </div >
        </div>
      </header >
    </>
  );
};

export default Header;
