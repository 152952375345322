import React from "react";
import TypeWriter from "./TypeWriter";
import { TiSocialFacebook } from "react-icons/ti";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import "../Resume/Resume.css";
import { TypeAnimation } from "react-type-animation";

const ResumeHero = ({ pageData }) => {


  const social = pageData?.social?.data;
 
  return (
    <>
      <div className="conatainer">
        <div className="flex-daretion">
          <div className="first-right-side">
            <p className="world-pa">WELCOME TO MY WORLD</p>
            <TypeWriter pageData={pageData} />
            <p><div dangerouslySetInnerHTML={{ __html: pageData?.content }} /></p>
            <div className="followus">
              <span className="find-us-title">find with me</span>
              <ul className="social-share">
                {
                  social?.facebook &&
                  <li className="facebook">
                    <a href={social.facebook} target="_blamk" >
                      <TiSocialFacebook />
                    </a>
                  </li>
                }
                {
                  social?.twitter &&
                  <li className="twiter">
                    <a href={social.twitter} target="_blank">
                      <FaXTwitter />
                    </a>
                  </li>
                }
                {
                  social?.linkedin &&
                  <li className="linkedin">
                    <a
                      href={social.linkedin}
                      target="_blank"
                    >
                      <FaLinkedinIn />
                    </a>
                  </li>
                }
              </ul>
            </div>
          </div>
          <div className="secon-left-side">
            <div className="thumnail">
              <div className="inner">
                {
                  pageData?.featured_image &&
                  <img
                    src={pageData?.featured_image} alt="Logo" loading="lazy" width="600" height="400" />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResumeHero;
