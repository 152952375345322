import React from "react";
import { TypeAnimation } from "react-type-animation";

function Type({pageData}) {

  const typingSequence = Object.values(pageData?.typing_text || {}).flatMap((item) => [
    item.text,
    1000,
  ]);  
  return (
    <div className="typemain">
      <h1 className="tittle-head">
        {" "}
        Hi, I’m <span className="fah-color">{pageData?.get_footer?.data?.owner}</span>
      </h1>
      <span className="a-color">
        a
        {
          pageData?.typing_text &&
            <TypeAnimation
              sequence={typingSequence}
              speed={50}
              repeat={Infinity}
            />
          }
      </span>
    </div>
  );
}

export default Type;
