import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "../Ourportfolio/Main.css";
import React, { useState, useEffect } from "react";
import Allmain from "./Allmain";
import "../Ourportfolio/Port.css";

 
const MainPortfolio = () => {

  const [portfolio, setPortfolio] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState('all');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
 
  useEffect(() => {
    const getTab = async () => {
      try {
        const url = 'https://www.fahimshakir.com/core/wp-json/custom-api/v1/portfolio_categories';
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        setTabs(result);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    
    const getPortfolio = async () => {
      try {
        const url = 'https://www.fahimshakir.com/core/wp-json/custom-api/v1/portfolio_posts';
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        setPortfolio(result);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    getTab();
    getPortfolio();
  }, []);
  
  const clickhandler = (slug) =>{
    setActiveTab(slug);
  }

  return (
    <>
      <div className="containert">
        <div className="react-tabs">
          <ul className="react-tabs__tab-list" role="tablist">
            {
              tabs.map((data,index)=>(
                <li className={`react-tabs__tab ${activeTab === data.slug ? 'react-tabs__tab--selected' : ''}`} key={index} onClick={()=>clickhandler(data.slug)}>
                  {data.title}
                    <span className="cbp-filter-counter">{data.post_count}</span>
                </li>
              ))
            } 
          </ul>
        </div>
        <div className={"hoxx "+ activeTab+'_container'}>
        {portfolio.map((card, index) => (
          <Allmain
            key={index}
            activeTab={activeTab}
            images={card.featured_image}
            title={card.title}
            link={card.link}
            category={card.categories}
          />
        ))}
      </div>
      </div>
    </>
  );
};

export default MainPortfolio;
