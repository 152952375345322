import React, { useState, useEffect } from "react";
import Slider from "react-slick";


const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-next" onClick={onClick}>
      ❱
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-prev" onClick={onClick}>
      ❰
    </div>
  );
};

const Technology = ({ data }) => {
  const respo = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 600);


  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 600);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const ourtecnology = data.use_technology;
  return (
    <section className="use-technology">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="text-center sec-review-ti">{data.technology_title}</h2>
            <p className="des-choose">{data.technology_content}</p>
          </div>
          <div className="col-sm-12 p-0	">
            <div className="use-technology-section">
              <div className="row">
                <div className="col-md-12">

                  {isDesktop ? (
                    <ul className="desktop-list">
                      {
                        ourtecnology != null &&
                        Object.entries(ourtecnology)
                          .filter(([key, value]) => value)
                          .map(([key, value], index) => (

                            <li key={index}>
                              <img
                                src={value.featured_image}
                                alt={value.title}
                                loading="lazy"
                                width="30px"
                                height="35px"
                              />
                              {value.title}
                            </li>
                          ))}
                    </ul>
                  ) : (
                    <Slider {...respo}>
                      {
                        ourtecnology != null &&
                        Object.entries(ourtecnology)
                          .filter(([key, value]) => value)
                          .map(([key, value], index) => (
                            <li key={index}>
                              <img
                                src={value.featured_image}
                                alt={value.title}
                                loading="lazy"
                                width="30px"
                                height="35px"
                              />
                              {value.title}
                            </li>
                          ))}
                    </Slider>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Technology;
