import React from 'react';
import '../Contact/Contact.css'
import ConCard from './ConCard';

const ContactCard = ({benifitContent}) => {

  return (
    <div className='cards-container'>
    <div className="d-flax">
    {
      benifitContent != null &&
      Object.entries(benifitContent) // Convert object to an array of [key, value]
        .filter(([key, value]) => value) // Filter out empty or undefined values
        .map(([key, value], index) => (
        <ConCard
          key={index}
          image={value.icon}
          title={value.title}
          description={value.description}
        />
      ))}
    </div>
    </div>
  );
};

export default ContactCard;
