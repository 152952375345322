import React, { useState } from "react";

const ContactFormHtml = () =>{
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [message, setMessage] = useState("");
    const [errors, setErrors] = useState({});
    const [formStatus, setFormStatus] = useState('');

    const validateForm = () => {
        const newErrors = {};

        if (!name) newErrors.name = "Name is required.";
        if (!email) {
            newErrors.email = "Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = "Email is invalid.";
        }
        if (!contactNumber) {
            newErrors.contactNumber = "Contact No is required.";
        } else if (!/^\d{10}$/.test(contactNumber)) {
            newErrors.contactNumber = "Contact No must be 10 digits.";
        }
        if (!message) newErrors.message = "Message is required.";

        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();

        if (Object.keys(formErrors).length === 0) {
            const formData = new FormData();
            formData.append('client-name', name);
            formData.append('client-email', email);
            formData.append('client-number', contactNumber);
            formData.append('message', message);

            try {
                const response = await fetch('https://www.fahimshakir.com/core/wp-json/custom/v1/send-email', {
                    method: 'POST',
                    body: formData
                });

                const result = await response.json();
                if (response.ok && result === 'Email sent successfully') {
                    console.log(result);
                    setFormStatus('Form submitted successfully!');
                    setName('');
                    setEmail('');
                    setContactNumber('');
                    setMessage('');
                    setErrors('');
                } else {
                    setFormStatus('Form submission failed. Please try again.');
                }
            } catch (error) {
                setFormStatus('Form submission failed. Please try again.');
                console.error('Error submitting form:', error);
            }
        } else {
            setErrors(formErrors);
        }
    };

    return(
        <form onSubmit={handleSubmit} className="contact-form">
            <input
                type="text"
                placeholder="Name"
                name="client-name"
                value={name}
                onChange={(e) => {
                    const input = e.target.value;
                    const sanitizedInput = input.replace(/[^a-zA-Z\s]/g, '').slice(0, 20);
                    setName(sanitizedInput);
                }}
            />
            {errors.name && <p className="error">{errors.name}</p>}
            <input
                type="text"
                placeholder="Email"
                value={email}
                name="client-email"
                onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email && <p className="error">{errors.email}</p>}
            <input
                type="tel"
                placeholder="Contact No"
                value={contactNumber}
                onChange={(e) => {
                    const input = e.target.value;
                    const sanitizedInput = input.replace(/[^0-9]/g, '').slice(0, 15);
                    setContactNumber(sanitizedInput);
                }}
            />
            {errors.contactNumber && <p className="error">{errors.contactNumber}</p>}
            <textarea
                placeholder="Message"
                className="textarea"
                value={message}
                onChange={(e) => {
                    const input = e.target.value;
                    const sanitizedInput = input.replace(/[^a-zA-Z\s]/g, '').slice(0, 250);
                    setMessage(sanitizedInput);
                }}
            ></textarea>
            {message.length === 250 && (
                <p className="error">Message cannot exceed 250 characters.</p>
                )}
            {errors.message && <p className="error">{errors.message}</p>}
            <button
                type="submit"
                name="sub"
                id="sub"
                className="btn btn-primary btn-lg"
            >
                Submit
            </button>
            {formStatus === 'Form submitted successfully!' && <p className="success-msg">Your message has been successfully sent. We will contact you very soon!</p>}
            {formStatus !== 'Form submitted successfully!' && <p className="error-msg">{formStatus}</p>}
        </form>
    )
}

export default ContactFormHtml;