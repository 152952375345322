import React, { useEffect, useState } from "react";
import ResumeHero from "./ResumeHero";
import ResumeSection from "./ResumeSection";
import Meta from "../Meta";
import '../Resume/Resume.css'

const Resume = () => {
  const [pageData, setPageData] = useState(null);
  useEffect(() => {
    fetch(`https://www.fahimshakir.com/core/wp-json/custom/v1/page/2095`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Service not found');
        }
        return response.json();
      })
      .then(data => {
        if (data && data.title) {
          setPageData(data);
        } else {
        }
      })
      .catch(error => {
        console.error('Error fetching service:', error);

      });
  }, []);

  const features = pageData?.features;
  const project_undertaken = pageData?.project_undertaken;
  return (
    <div>
      <Meta
        title={pageData?.seo_title || ''}
        description={pageData?.seo_description || ''}
        keywords={pageData?.seo_keywords || ''}
        head_tag={pageData?.head_tag || ''}
      />
      <ResumeHero pageData={pageData}/>
      <div className="main-card">
        <div className="conatainer">
          <div>
            <span className="subtitle">Features</span>
            <h2 className="sec-review-ti">What I Do</h2>
          </div>
          <div className="reusable-compnent">
            {
              features != null &&
              Object.entries(features) // Convert object to an array of [key, value]
                .filter(([key, value]) => value) // Filter out empty or undefined values
                .map(([key, value], index) => (
                  <div className="reusable-card">
                    <div className="menu-icon">
                      <img src={value.featured_image} alt="" width={50} height={50} />
                    </div>
                    <div className="content">
                      <a href="#">
                        <div className="title">{value.title}</div>
                        <div className="description">{value.description}</div>
                      </a>
                    </div>
                    <div className="arrow-icon">
                      <a href="#">➤</a>
                    </div>
                  </div>
              ))
            }
          </div>
        </div>
      </div>
      <ResumeSection pageData={pageData} />
      <div className="remain-bg">
        <div className="col-md-12">
          <div dangerouslySetInnerHTML={{ __html: pageData?.project_undertaken_content }} />
        </div>

        <div className="conatainerve">
          <ul className="Newflax">
            {
              project_undertaken != null &&
              Object.entries(project_undertaken)
                .filter(([key, value]) => value)
                .map(([key, value], index) => (
                  <li key={index}>{value.title}</li>
              ))
            }
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Resume;
