import React, { useEffect, useState } from 'react';
// import AboutHero from "./AboutHero";
import Meta from '../Meta';
import "../About/AboutHero.css";
import image_aboute from "../../components/image/FAHIM.jpg";

const About = () => {

  const [pageData, setPageData] = useState(null);
  useEffect(() => {
    fetch(`https://www.fahimshakir.com/core/wp-json/custom/v1/page/2087`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Service not found');
        }
        return response.json();
      })
      .then(data => {
        if (data && data.title) {
          setPageData(data);
        } else {
        }
      })
      .catch(error => {
        console.error('Error fetching service:', error);

      });
  }, []);

  return (
    <div>
      <Meta
        title={pageData?.seo_title || ''}
        description={pageData?.seo_description || ''}
        keywords={pageData?.seo_keywords || ''}
        head_tag={pageData?.head_tag || ''}
      />
      <div>
        <div className="about">
          <h1 className="hed">{pageData?.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: pageData?.banner_content }} />
        </div>

        <div className="containerr">
          <div className="flax">
            <div className="image-div">
              {
                pageData?.featured_image &&
                <img src={pageData?.featured_image} alt="fahim" loading="lazy" />
              }
            </div>
            <div className="pardiv">
              <div dangerouslySetInnerHTML={{ __html: pageData?.content }} />
            </div>
          </div>

          <div className="second-div">
            <div dangerouslySetInnerHTML={{ __html: pageData?.footer_description }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
