import React from "react";

const ResumeSingleCard = ({index,value}) =>{
    return(
      <div className="resume-single-list" key={index}>
        <div className="inner">
          <div className="heading">
            <div className="title">
              <h4 className="list-title">
                {value.title}
              </h4>
              <span className="list-sub-title">
              {value.description}
              </span>
            </div>
            <div className="date-of-time">
              <span>{value.short_content}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
  export default ResumeSingleCard;