import React from "react";
import ResumeSingle from "./ResumeSingleCard"

const Education = ({ pageData }) => {

  const education_quality_title = pageData?.education_quality_title;
  const education_quality = pageData?.education_quality;
  const job_experience_title = pageData?.job_experience_title;
  const job_experience = pageData?.job_experience;

  return (
    <div>
      <div className="personal-experience-content">
        <div className="personal-experience-inner">
          <div className="content">
            <h4 className="maintitle">{education_quality_title}</h4>
            <div className="experience-list">
              {
                education_quality != null &&
                Object.entries(education_quality) // Convert object to an array of [key, value]
                  .filter(([key, value]) => value) // Filter out empty or undefined values
                  .map(([key, value], index) => (
                    <ResumeSingle value={value} index={index}/>
                  ))
              }
            </div>
          </div>
        </div>
        <div className="personal-experience-inner">
          <div className="content">
            <h4 className="maintitle">{job_experience_title}</h4>
            <div className="experience-list">
              {
                  job_experience != null &&
                  Object.entries(job_experience) // Convert object to an array of [key, value]
                    .filter(([key, value]) => value) // Filter out empty or undefined values
                    .map(([key, value], index) => (
                      <ResumeSingle value={value} index={index}/>
                    ))
                }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Education;