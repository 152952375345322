import React from "react";


const Chooseus = ({ data }) => {

  const mydata = data.why_choose_us;
  return (
    <>
      <section className="choose-us">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="text-center sec-review-ti">{data.why_choose_us_title}</h3>
              <p className="des-choose">{data.why_choose_us_content}</p>
            </div>
            <div className="col-sm-12 p-0	">
              <div className="choose-us-section">
                {
                  mydata != null &&
                  Object.entries(mydata)
                    .filter(([key, value]) => value)
                    .map(([key, value], index) => (

                      <div className="card" key={index}>
                        <div className="icon">
                          <i className={value.icon}></i>
                        </div>
                        <div className="title">{value.title}</div>
                      </div>

                    ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Chooseus;
